<template>
    <img
        :class="props.class"
        :src="props.content.url"
        :alt="props.content.alt"
        :style="{
            height: dimension === 'height' && isNotSvg ? props.content.height + 'px' : null,
            width: dimension === 'width' && isNotSvg ? props.content.width + 'px' : null,
        }"
    >
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    class: {
        type: [String, Object, Array],
        required: false,
        default: '',
    },
    content: {
        type: Object,
        required: true,
    },
    dimension: {
        type: String,
        required: false,
        default: 'height',
    },
});

const isNotSvg = computed(() => {
    return props.content?.filename?.indexOf('.svg') === -1;
});
</script>
